import { createRouter, createWebHistory } from 'vue-router'
import store from "@/state/store";
import appConfig from "../../app.config";

import getTokenCheck from '../state/api/Settings/tokenCheck';
import menu from '../state/api/Settings/menu';



const tokenCheck = async(to,from,next)=>{
  let token = await getTokenCheck();
  if(!token){    
    localStorage.removeItem("user");
    return next('/login');
  }
  else{
    const user = localStorage.getItem('user')
    if(user == null || user == undefined){
      return next('/login');
    }
    else{
      // const routesData = await menu();
      // const combinedRolePages = [];
      // routesData.forEach(route => {
      //   if (Array.isArray(route.rolePage)) {
      //     combinedRolePages.push(...route.rolePage);
      //   }
      // });

      // combinedRolePages.forEach(route => {
      //     const routeConfig = {
      //       path: "/"+route.url.split('?')[0],
      //       //path: "/"+route.url,
      //       name: route.title,
      //       meta: {
      //         title: route.title,
      //         authRequired: true,
      //       },
      //       props: route => ({ typeId: Number(route.query.typeId) || 0 }),
      //       component: () => import(`@/views/${route.url.split('?')[0]}.vue`)
      //     };
      //     router.addRoute(routeConfig);
      // });
      next();  
    } 
  }
}

// const tokenCheck = async(to,from,next)=>{
//     let token = await getTokenCheck();
//     if(!token){
//       localStorage.removeItem("user");
//       return next('/login');
//     }

//     else{
//       const user = localStorage.getItem('user')

//       if(!user){
//         return next('/login');
//       }

//       else{
//         const routesData = await getMenu();
//         routesData.forEach(route => {
//             const routeConfig = {
//               path: "/"+route.url.split('?')[0],
//               //path: "/"+route.url,
//               name: route.title,
//               meta: {
//                 title: route.title,
//                 authRequired: true,
//               },
//               props: route => ({ typeId: Number(route.query.typeId) || 0 }),
//               component: () => import(`@/views/${route.url.split('?')[0]}.vue`)
//             };
//             router.addRoute(routeConfig);
//         });
//         next();  
//       } 
      
//     } 
// }

const routes =  [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Anasayfa",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/banner/banner-list",
    name: "banner-list",
    meta: {
      title: "Banner List",
      authRequired: true,
    },
    component: () => import("../views/banner/bannerList.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/banner-add",
    name: "banner-add",
    meta: {
      title: "Banner Add",
      authRequired: true,
    },
    component: () => import("../views/banner/bannerAdd.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/banner-detail/:id",
    name: "banner-detail",
    props:true,
    meta: {
      title: "Banner Detail",
      authRequired: true,
    },
    component: () => import("../views/banner/bannerDetail.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/banner-gallery/:id",
    name: "banner-gallery",
    props:true,
    meta: {
      title: "Banner Gallery",
      authRequired: true,
    },
    component: () => import("../views/banner/bannerAddGallery.vue"),
    //beforeEnter:tokenCheck
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Profile Detail",
      authRequired: true,
    },
    component: () => import("../views/profile/index.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/contents/content-detail/:id/:currentTypeId",
    name: "content-detail",
    props:true,
    meta: {
      title: "Content Detail",
      authRequired: true,
    },
    component: () => import("../views/contents/ContentDetail.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/contents/content-add/:id",
    name: "content-add",
    props:true,
    meta: {
      title: "Content Add",
      authRequired: true,
    },
    component: () => import("../views/contents/ContentAdd.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/forms/customer-comment-add",
    name: "customer-comment-add",
    meta: {
      title: "Customer Comment Add",
      authRequired: true,
    },
    component: () => import("../views/forms/customer-comment-add.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/forms/customer-comment-detail/:id",
    name: "customer-comment-detail",
    props:true,
    meta: {
      title: "Customer Comment Detail",
      authRequired: true,
    },
    component: () => import("../views/forms/customer-comment-detail.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/forms/form-detail/:id",
    name: "form-detail",
    props:true,
    meta: {
      title: "Form Detail",
      authRequired: true,
    },
    component: () => import("../views/forms/form-detail.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/contents/content-add-gallery/:id",
    name: "content-add-gallery",
    props:true,
    meta: {
      title: "Content Add Gallery",
      authRequired: true,
    },
    component: () => import("../views/contents/content-add-gallery.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: "/contents/content-list",
    name: "Sayfalar",
    props: (route) => ({
      typeId: Number(route.query.typeId) || 0
    }),
    meta: {
      title: "Sayfalar",
      authRequired: true,
    },
    component: () => import("../views/contents/content-list.vue"),
    beforeEnter:tokenCheck
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    component: () => import('../views/error/error404View.vue'),
    beforeEnter:tokenCheck
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const user = localStorage.getItem('user');

  if (to.path === '/login') {
    // Eğer kullanıcı zaten giriş yapmışsa ve /login sayfasına gitmeye çalışıyorsa
    // Bunun yerine ana sayfaya yönlendirelim
    if (user != null) {
      return next('/');
    }
  } else {
    // Kullanıcı giriş yapmışsa menüyü oluşturalım ve devam edelim
    if (user != null) {
      const routesData = await menu();
      let combinedRolePages = [];
      routesData.forEach(route => {
        combinedRolePages = combinedRolePages.concat(route.rolePage);
      });

      combinedRolePages.forEach(route => {
        const routeConfig = {
          path: "/" + route.url.split('?')[0],
          name: route.title,
          meta: {
            title: route.title,
            authRequired: true,
          },
          props: route => ({ typeId: Number(route.query.typeId) || 0 }),
          component: () => import(`@/views/${route.url.split('?')[0]}.vue`)
        };
        router.addRoute(routeConfig);
      });
      
    } else {
      // Kullanıcı giriş yapmamışsa /login sayfasına yönlendirelim
      return next('/login');
    }
  }

  next();
});

router.beforeResolve(async (to, from, next) => {
  try {
    for (const route of to.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(to, from, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  document.title = to.meta.title + ' | ' + appConfig.title;
  next();
});

export default router;
