import crypted from "./crypted"
//import getToken from "../Token/getToken";

const getUserCheck = async()=>{
    const user = localStorage.getItem('user')
    if(user){
        const decryptedUser = crypted.decryptedData(user);
        return JSON.parse(decryptedUser)
    }
    // else{
    //     const { token } = getToken();
    //     const response = await token();
    //     const decryptedAccessToken = crypted.decryptedData(response);
    //     return decryptedAccessToken
    // }
}
export default getUserCheck