import crypted from "./crypted"
const getTokenCheck = async()=>{
    const accessToken = localStorage.getItem('accessToken')
    if(accessToken){
        const decryptedAccessToken = crypted.decryptedData(accessToken);
        return JSON.parse(decryptedAccessToken)
    }
    else{
        false
    }
    // else{
    //     const { token } = getToken();
    //     const response = token();
    //     const decryptedAccessToken = crypted.decryptedData(response);
    //     return decryptedAccessToken
    // }
}
export default getTokenCheck