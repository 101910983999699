import crypted from "./crypted"
const getMenu = async()=>{
    const menu = localStorage.getItem('menu')
    if(menu){
        const decryptedMenu = crypted.decryptedData(menu);
        return JSON.parse(decryptedMenu)
    }
    else{
        false
    }
    // else{
    //     const { token } = getToken();
    //     const response = token();
    //     const decryptedAccessToken = crypted.decryptedData(response);
    //     return decryptedAccessToken
    // }
}
export default getMenu