import CryptoJS from 'crypto-js';
const key ="82f2ceed4c503896c8a291e560bd4325"
function encryptData(dataText) {    
    const encryptedText = CryptoJS.AES.encrypt(dataText, key).toString();
    return encryptedText.toString();
}

function decryptedData(dataText) {
    const decryptedText = CryptoJS.AES.decrypt(dataText, key).toString(CryptoJS.enc.Utf8)
    return decryptedText.toString();
}

export default {encryptData,decryptedData}